import { useEffect, useState,Fragment } from 'react';
import cambriLogo from '../../../src/icons/Cambri-white.png';
import cambriLogoIelts from '../../../src/icons/cambrilogo-ielts.svg';
import cambriLogoSat from '../../../src/icons/cambrilogo-sat.svg';
import cambriLogo2 from '../../../src/icons/Cambri-blue.png';
import cambriLogoMob from '../../../src/icons/mobile_view_cambri_logo.svg';
import './navbar.css';
import { useLocation,Link } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router';
import useNavbar from '../reUsable/changeNavBar';
import handleScrollToPricing from '../reUsable/scroll';

const NavMenu = () => {
  const [colorChange, setColorchange] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const currentRoute = location.pathname;

useNavbar(setColorchange)
  useEffect(() => {
    if (window.location.pathname === '/') {
      localStorage.setItem('ielts', false.toString());
      localStorage.setItem('sop', false.toString());
      localStorage.setItem('aboutUs', false.toString());
      localStorage.setItem('sat', false.toString());
    }
    if (window.location.pathname === '/aboutus') {
      localStorage.setItem('aboutUs', true.toString());
      localStorage.setItem('ielts', false.toString());
      localStorage.setItem('sop', false.toString());
      localStorage.setItem('sat', false.toString());
      localStorage.setItem('gre', false.toString());
    }
    if (window.location.pathname === '/ielts') {
      localStorage.setItem('ielts', true.toString());
      localStorage.setItem('aboutUs', false.toString());
      localStorage.setItem('sop', false.toString());
      localStorage.setItem('sat', false.toString());
      localStorage.setItem('gre', false.toString());
    }
    if (window.location.pathname === '/sop') {
      localStorage.setItem('sop', true.toString());
      localStorage.setItem('aboutUs', false.toString());
      localStorage.setItem('ielts', false.toString());
      localStorage.setItem('sat', false.toString());
      localStorage.setItem('gre', false.toString());
    }
    if (window.location.pathname === '/sat' || window.location.pathname === '/gre') {
      localStorage.setItem('sop', false.toString());
      localStorage.setItem('aboutUs', false.toString());
      localStorage.setItem('ielts', false.toString());
      localStorage.setItem('sat', true.toString());
      localStorage.setItem('gre', false.toString());
    }
     if (window.location.pathname === '/gre') {
       localStorage.setItem('sop', false.toString());
       localStorage.setItem('aboutUs', false.toString());
       localStorage.setItem('ielts', false.toString());
       localStorage.setItem('sat', false.toString());
      localStorage.setItem('gre', true.toString());
     }
  }, []);

  const handleGetStarted = () => {
    (window as any).fbq('track', 'Get Started');
    if (window.location.pathname === '/') {
      window.open('https://app.cambri.ai/signupnew', '_blank', 'noopener');
    }
    if (window.location.pathname === '/aboutus') {
      window.open('https://app.cambri.ai/signupnew', '_blank', 'noopener');
    }
    if (window.location.pathname === '/sop' || window.location.pathname === '/payments') {
      window.open('https://app.cambri.ai/signupnew', '_blank', 'noopener');
    }
    if (window.location.pathname === '/ielts' || window.location.pathname === '/ieltspayments') {
      window.open('https://ielts.web.cambri.ai', '_blank', 'noopener');
    }
    if (window.location.pathname === '/sat') {
      window.open('https://business.sat.cambri.ai/', '_blank', 'noopener');
    }
    if (window.location.pathname === '/gre') {
      window.open('https://gre-ux.web.app/', '_blank', 'noopener');
    }
  };

  const ieltsPricingRoute = () => {
    if (currentRoute != '/' && window.location.pathname.includes('/sop')) {
      return '/payments';
    } else if (currentRoute != '/' && window.location.pathname.includes('/ielts')) {
      return '/ieltspayments';
    }else if(currentRoute != '/' && window.location.pathname.includes('/sat')) {
      return '/satpayments';
    }
     else {
      return '';
    }
  }

  const ieltsColors = () => {
    if (colorChange && window.location.pathname.includes('/ielts')) {
      return 'white';
    } else if (colorChange && !window.location.pathname.includes('/ielts')) {
      return '#8431FC';
    } else {
      return 'white';
    }
  }

  const getStartedColors = () => {
    if (window.location.pathname.includes('/ielts')) {
      return 'text-[#E32D49] border-[2px] border-black';
    } else if (window.location.pathname.includes('/sat') && !colorChange) {
      return 'text-black border-[2px] border-[#5057ff]';
    } else if (window.location.pathname.includes('/payments') && !colorChange) {
      return 'text-[#8431FC] border-[#8431FC] border-[2px]';
    } else if (colorChange) {
      return 'text-white';
    } else {
      return 'text-purple-700';
    }
  };

  const getBookADemoColors = () => {
    if (window.location.pathname.includes('/ielts')) {
      return colorChange
        ? 'text-black border-black border-[2px]'
        : 'text-black border-[2px] border-black';
    } else if (!colorChange && window.location.pathname.includes('/sat')) {
      return 'text-[#5057ff] border-[#5057ff]';
    } else if (
      window.location.pathname.includes('/payments') ||
      window.location.pathname.includes('/bookademo')
    ) {
      return 'text-[#8431FC] border-[#8431FC]';
    } else if (colorChange) {
      return 'text-[#8431FC] border-[#8431FC]';
    } else {
      return 'text-white border-white';
    }
  };

  const getBlogsColors = () => {
    if (window.location.pathname.includes('/ielts')) {
      return 'text-black';
    } else if (!colorChange && window.location.pathname === '/sat') {
      console.log('SAA');
      return 'text-[#5057ff]';
    } else if (!colorChange && window.location.pathname === '/gre') {
      return 'text-[orange]';
    } else if (!colorChange && window.location.pathname === '/satpayments') {
      return 'text-[#5057ff]';
    } else if (
      window.location.pathname.includes('/payments') ||
      window.location.pathname.includes('/bookademo')
    ) {
      return 'text-[#8431FC]';
    } else if (colorChange) {
      console.log('IELS');
      return 'text-[#8431FC]';
    } else {
      return 'text-white';
    }
  }

  const getPricingColors = () => {
    if (window.location.pathname.includes('/ielts')) {
      return 'text-black';
    } else if (!colorChange && window.location.pathname==='/sat') {
      console.log("SAA")
      return 'text-[#5057ff]';
    } 
    else if (!colorChange && window.location.pathname==='/satpayments') {
      return 'text-[#5057ff]';
    } else if (
      window.location.pathname.includes('/payments') ||
      window.location.pathname.includes('/bookademo') 
    ) {
      return 'text-[#8431FC]';
    } else if (colorChange && window.location.pathname==='/satpayments'){
      return 'text-[#8431FC]';
    }
     else if (colorChange) {
      return 'text-[#8431FC]';
    } else {
      return 'text-white';
    }
  };

  return (
    <div
      className={`${
        colorChange
          ? 'bg-white mx-auto border-white border rounded-b-[20px]'
          : 'xl:w-full lg:w-full '
      } flex  z-50 items-center justify-between px-5 sm:px-10 h-[4.5rem] sm:h-20 fixed w-full ${
        window.location.pathname.includes('/payments')
          ? 'mt-[0px]'
          : window.location.pathname.includes('/satpayments')
          ? 'mt-[-140px]'
          : window.location.pathname.includes('/bookademo') && 'mt-[-192px]'
      }`}
    >
      <a href="#" className="flex items-center">
        <div>
          <a href="https://cambri.ai/" id="logo">
            <img
              src={
                (colorChange && !window.location.pathname.includes('/ielts')) ||
                (colorChange && window.location.pathname.includes('/sat'))
                  ? cambriLogo2
                  : window.location.pathname.includes('/ielts')
                  ? cambriLogoIelts
                  : window.location.pathname.includes('/sat')
                  ? cambriLogoSat
                  : !colorChange && window.location.pathname.includes('/payments')
                  ? cambriLogo2
                  : !colorChange && window.location.pathname.includes('/bookademo')
                  ? cambriLogo2
                  : window.location.pathname.includes('/gre')
                  ? cambriLogo2
                  : cambriLogo
              }
              className="sm:w-full w-[124px] h-[24px] sm:h-auto"
              //   className="h-6 mr-3 sm:h-9"
              alt="Landwind Logo"
            />
          </a>
        </div>
      </a>

      <div
        className="items-center hidden  lg:block  justify-between   lg:w-auto"
        id="mobile-menu-2"
      >
        <ul
          className={`flex flex-row mt-4 text-sm lg:text-sm xl:text-xl font-[850] lg:flex-row lg:space-x-8 lg:mt-0 uppercase`}
        >
          <li>
            {/* <a
                href="/ielts"
                className={`block py-2 pl-2 pr-2 ${colorChange ? 'text-[#8431FC]' : 'text-white'}`}
              >
                Products
              </a> */}
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button
                  className={`inline-flex w-full justify-center text-20px rounded-md bg-black bg-opacity-0 px-4 py-2  hover:bg-opacity-0 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 block pl-2 pr-2 lg:mt-[2px] ${
                    (colorChange && !window.location.pathname.includes('/ielts')) ||
                    (colorChange && window.location.pathname.includes('/sat')) ||
                    (colorChange && window.location.pathname.includes('/gre'))
                      ? 'text-[#8431FC]'
                      : window.location.pathname.includes('/ielts')
                      ? 'text-black'
                      : window.location.pathname.includes('/sat')
                      ? 'text-[#5057ff]'
                      : !colorChange && window.location.pathname.includes('/payments')
                      ? 'text-[#8431FC]'
                      : !colorChange && window.location.pathname.includes('/bookademo')
                      ? 'text-[#8431FC]'
                      : colorChange && window.location.pathname.includes('/')
                      ? 'text-[#8431FC]'
                      : window.location.pathname.includes('/gre')
                      ? 'text-[orange]'
                      : 'text-white'
                  }`}
                >
                  PRODUCTS
                  <ChevronDownIcon
                    className={`ml-2 -mr-1 h-5 w-5 lg:-mt-[3px]  ${
                      (colorChange && !window.location.pathname.includes('/ielts')) ||
                      (colorChange && window.location.pathname.includes('/sat')) ||
                      (colorChange && window.location.pathname.includes('/gre'))
                        ? 'text-[#8431FC]'
                        : window.location.pathname.includes('/ielts')
                        ? 'text-black'
                        : window.location.pathname.includes('/sat')
                        ? 'text-[#5057ff]'
                        : !colorChange && window.location.pathname.includes('/payments')
                        ? 'text-[#8431FC]'
                        : !colorChange && window.location.pathname.includes('/bookademo')
                        ? 'text-[#8431FC]'
                        : colorChange && window.location.pathname.includes('/')
                        ? 'text-[#8431FC]'
                        : window.location.pathname.includes('/gre')
                        ? 'text-[orange]'
                        : 'text-white'
                    }`}
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="px-1 py-1 ">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active && colorChange && !window.location.pathname.includes('/ielts')
                              ? 'bg-[#8431FC] text-white'
                              : active &&
                                !colorChange &&
                                !window.location.pathname.includes('/ielts')
                              ? 'bg-[#8431FC] text-white'
                              : active &&
                                !colorChange &&
                                window.location.pathname.includes('/ielts')
                              ? 'bg-black text-white'
                              : active && colorChange && window.location.pathname.includes('/ielts')
                              ? 'bg-black text-white'
                              : !colorChange && window.location.pathname.includes('/ielts')
                              ? 'text-black'
                              : colorChange && window.location.pathname.includes('/ielts')
                              ? 'text-black'
                              : window.location.pathname.includes('/sat')
                              ? 'text-[#5057ff]'
                              : 'text-[#8431FC]'
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          onClick={() => {
                            navigate('/ielts');
                          }}
                        >
                          IELTS
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                  <div className="px-1 py-1 ">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active && colorChange && !window.location.pathname.includes('/ielts')
                              ? 'bg-[#8431FC] text-white'
                              : active &&
                                !colorChange &&
                                !window.location.pathname.includes('/ielts')
                              ? 'bg-[#8431FC] text-white'
                              : active &&
                                !colorChange &&
                                window.location.pathname.includes('/ielts')
                              ? 'bg-black text-white'
                              : active && colorChange && window.location.pathname.includes('/ielts')
                              ? 'bg-black text-white'
                              : !colorChange && window.location.pathname.includes('/ielts')
                              ? 'text-black'
                              : colorChange && window.location.pathname.includes('/ielts')
                              ? 'text-black'
                              : window.location.pathname.includes('/sat')
                              ? 'text-[#5057ff]'
                              : 'text-[#8431FC]'
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          onClick={() => {
                            navigate('/');
                          }}
                        >
                          SOP Writer
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                  <div className="px-1 py-1 ">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active && colorChange && !window.location.pathname.includes('/ielts')
                              ? 'bg-[#8431FC] text-white'
                              : active &&
                                !colorChange &&
                                !window.location.pathname.includes('/ielts')
                              ? 'bg-[#8431FC] text-white'
                              : active &&
                                !colorChange &&
                                window.location.pathname.includes('/ielts')
                              ? 'bg-black text-white'
                              : active && colorChange && window.location.pathname.includes('/ielts')
                              ? 'bg-black text-white'
                              : !colorChange && window.location.pathname.includes('/ielts')
                              ? 'text-black'
                              : colorChange && window.location.pathname.includes('/ielts')
                              ? 'text-black'
                              : window.location.pathname.includes('/sat')
                              ? 'text-[#5057ff]'
                              : 'text-[#8431FC]'
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          onClick={() => {
                            navigate('/sat');
                          }}
                        >
                          SAT
                        </button>
                      )}
                    </Menu.Item>
                  </div>

                  <div className="px-1 py-1 ">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active && colorChange && !window.location.pathname.includes('/ielts')
                              ? 'bg-[#8431FC] text-white'
                              : active &&
                                !colorChange &&
                                !window.location.pathname.includes('/ielts')
                              ? 'bg-[#8431FC] text-white'
                              : active &&
                                !colorChange &&
                                window.location.pathname.includes('/ielts')
                              ? 'bg-black text-white'
                              : active && colorChange && window.location.pathname.includes('/ielts')
                              ? 'bg-black text-white'
                              : !colorChange && window.location.pathname.includes('/ielts')
                              ? 'text-black'
                              : colorChange && window.location.pathname.includes('/ielts')
                              ? 'text-black'
                              : window.location.pathname.includes('/sat')
                              ? 'text-[#5057ff]'
                              : 'text-[#8431FC]'
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          onClick={() => {
                            navigate('/gre');
                          }}
                        >
                          GRE
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </li>
          <li>
            <Link
              to="/aboutus"
              className={`block py-2 pl-2 pr-2 ${
                colorChange && window.location.pathname.includes('/ielts')
                  ? 'text-black'
                  : !colorChange && window.location.pathname.includes('/ielts')
                  ? 'text-black'
                  : colorChange && !window.location.pathname.includes('/ielts')
                  ? 'text-[#8431FC]'
                  : window.location.pathname.includes('/sat')
                  ? 'text-[#5057ff]'
                  : !colorChange && window.location.pathname.includes('/payments')
                  ? 'text-[#8431FC]'
                  : !colorChange && window.location.pathname.includes('/bookademo')
                  ? 'text-[#8431FC]'
                  : window.location.pathname.includes('/gre')
                  ? 'text-[orange]'
                  : 'text-white'
              }`}
            >
              About Us
            </Link>
          </li>
          {!['/aboutus', '/ieltspayments', '/payments', '/sat', '/gre'].some((path) =>
            window.location.pathname.includes(path)
          ) && (
            <li>
              <Link
                to={ieltsPricingRoute()}
                target={currentRoute != '/' ? '_blank' : '_self'}
                className={`block py-2 pl-2 pr-2 ${getPricingColors()} cursor-pointer`}
                onClick={(event) => {
                  if (currentRoute == '/') {
                    handleScrollToPricing('pricing', event);
                  }
                }}
              >
                Pricing
              </Link>
            </li>
          )}
          {
            <li>
              <Link to="/blogs" className={`block py-2 pl-2 pr-2 ${getBlogsColors()}`}>
                Blogs
              </Link>
            </li>
          }
        </ul>
      </div>

      <div className=" items-center lg:flex hidden ">
        <button
          onClick={() => {
            (window as any).fbq('track', 'attempt_login');
            navigate('/bookademo');
          }}
          className={`${getBookADemoColors()}  cursor-pointer mr-4   w-77  left-1047 font-semibold text-[18px] lg:text-sm py-[6px] xl:text-xl  px-10 border border-solid rounded-[30px] `}
        >
          Book a demo
        </button>
        {!window.location.pathname.includes('/bookademo') && (
          <button
            onClick={handleGetStarted}
            style={{
              background: ieltsColors(),
            }}
            className={`${getStartedColors()} bg-white hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-bold rounded-full text-[18px] px-10 lg:text-sm py-2 h-15 xl:text-xl sm:mr-2 lg:mr-0 focus:outline-none dark:focus:ring-purple-800 `}
          >
            Get Started
          </button>
        )}
      </div>
      <div className="mobile__nav relative block  lg:hidden ">
        <input type="checkbox" className="mobile__nav_checkbox" id="nav-toggle" />
        <label htmlFor="nav-toggle" className="mobile__nav_button">
          <span className="mobile__nav_icon"></span>
        </label>
        <div className="mobile__nav_background"></div>
        <div className="mobile__nav_navigation">
          <ul className="mobile__nav_list mt-6">
            <li className="mobile__nav__item">
              {' '}
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button
                    className={`inline-flex w-full justify-center text-[1.5rem] rounded-md bg-black bg-opacity-0 px-4 py-2  hover:bg-opacity-0 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75  pl-2 pr-2 text-[#9531F3]
                       mobile__nav__link font-bold mt-4`}
                  >
                    Products
                    <ChevronDownIcon
                      className={`ml-2 -mr-1 h-5 w-5 text-[#8431FC`}
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 mt-2 w-40 sm:w-[200px] origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-1 py-1 ">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${
                              active ? 'bg-[#8431FC] text-white' : 'text-[#8431FC]'
                            } group flex w-full items-center rounded-md px-2 py-2 text-base sm:text-xl font-bold`}
                            onClick={() => {
                              navigate('/ielts');
                            }}
                          >
                            IELTS
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                    <div className="px-1 py-1 ">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${
                              active ? 'bg-[#8431FC] text-white' : 'text-[#8431FC]'
                            } group flex w-full items-center rounded-md px-2 py-2 text-base sm:text-xl font-bold`}
                            onClick={() => {
                              navigate('/');
                            }}
                          >
                            SOP Writer
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                    <div className="px-1 py-1 ">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${
                              active ? 'bg-[#8431FC] text-white' : 'text-[#8431FC]'
                            } group flex w-full items-center rounded-md px-2 py-2 text-base  font-bold`}
                            onClick={() => {
                              navigate('/sat');
                            }}
                          >
                            SAT
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </li>
            <li className="mobile__nav__item">
              {' '}
              <Link to="/aboutus" className="mobile__nav__link">
                {' '}
                About Us
              </Link>
            </li>
            <li className="mobile__nav__item">
              {' '}
              <Link
                to={ieltsPricingRoute()}
                target={currentRoute != '/' ? '_blank' : '_self'}
                className={`mobile__nav__link`}
                onClick={(event) => {
                  if (currentRoute == '/') {
                    handleScrollToPricing('pricing', event);
                  }
                }}
              >
                Pricing
              </Link>
            </li>
            <li className="mobile__nav__item">
              {' '}
              <Link to="/blogs" className="mobile__nav__link mb-14">
                {' '}
                Blogs
              </Link>
            </li>
            <li className="get_started_btn">
              <button
                onClick={handleGetStarted}
                className={`lg:hidden  py-3 text-white  rounded-full text-[1rem] px-10 lg:px-10  lg:py-3 sm:mr-2 lg:mr-0 font-medium`}
              >
                Get Started
              </button>
            </li>

            <li className="log_in_btn lg:hidden cursor-pointer mr-4 mb-4  text-[#8431FC] w-77 h-37 top-21 left-1047 font-medium	 text-2xl leading-10">
              <button onClick={() => navigate('/bookademo')}>Book a demo</button>
            </li>
            <li>
              <img
                src={cambriLogoMob}
                className="m-auto mt-20"
                //   className="h-6 mr-3 sm:h-9"
                alt="Landwind Logo"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavMenu;
