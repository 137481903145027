import { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Loading } from './components/Loading';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from './pages/footer';
import cambriLogo from './icons/Cambri-blue.png'
import BookaDemo from './pages/book-a-demo/bookademo';
import NavMenu from './pages/home_page/navmenu';
import SATPayments from './pages/home_page/payments/satPayments';

/** LOAD ROUTES **/
// Set up all routers as lazy routes. THis is important because when the app loads, we want the landing page to split load quickly for SEO purposes.
// Hence make it default to setup all routes as lazy routes.

const LandingPage = lazy(() => import('./pages/home_page/index'));
const AboutUsPage = lazy(() => import('./pages/about-us/index'));
const ContactUsPage = lazy(() => import('./pages/contact-us/index'));
const BlogsPage = lazy(() => import('./pages/blogs/index'));
const IeltsPage = lazy(()=>import('./pages/products/index'));
const Payments = lazy(()=>import('./pages/home_page/payments/paymentSection'));
const Navbar = lazy(()=>import('./pages/home_page/navmenu'));
const SatPage = lazy(()=>import('./pages/home_page/satPage'));
const GrePage = lazy(() => import('./pages/home_page/grePage'));
const IeltsPayments = lazy(()=>import('./pages/home_page/payments/ieltsPayments'));


/*******/

export const App = () => {

  useEffect(() => {
    // Initialize Facebook Pixel
    (window as any).fbq('init', '221242447615167');
    (window as any).fbq('track', 'Landing Page');
  }, []);

  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route index element={<LandingPage />} />
          <Route path="/sop" element={<LandingPage />} />
          <Route path="/aboutus" element={<AboutUsPage />} />
          <Route path="/contactus" element={<ContactUsPage />} />
          <Route path="/ielts" element={<IeltsPage />} />
          <Route path="/sat" element={<SatPage />} />
          <Route path="/gre" element={<GrePage />} />
          <Route path="/blogs" element={<BlogsPage />} />
          <Route path="/blog" element={<BlogsPage />} />
          <Route path="/bookademo" element={<BookaDemo />} />
          <Route
            path="/payments"
            element={
              <>
                <NavMenu />
                <Payments />
                <Footer page={'payments'} />
              </>
            }
          />
          <Route
            path="/satpayments"
            element={
              <>
                <NavMenu />
                <SATPayments />
                <Footer page={'satpayments'} />
              </>
            }
          />
          <Route
            path="/ieltspayments"
            element={
              <>
                <NavMenu />
                <IeltsPayments />
                <Footer page={'ieltspayments'} />
              </>
            }
          />
        </Routes>
      </Suspense>
      <ToastContainer />
    </BrowserRouter>
  );
};
